@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.center {
  text-align: center;
}

h1 {
  font-family: Raleway, sans-serif;
  font-weight: 600;
}

.error {
  background-color: #f2cdcd;
  border: 2px solid #db3737;
}

/* Ensure your styles are specific enough */

.custom-margin-top {
  margin-top: -2rem;
}

.services_ul {
  text-align: center;
}

.services_ul li {
  padding: 3px 10px;
}

.services_ul li a:hover {
  color: rgb(26 86 219);
}

.custom_font_raleway {
  font-family: "Raleway", sans-serif !important;
}
body {
  overflow-x: hidden;
}
