/* Use camelCase class names for CSS Modules */

.flipCard {
  background-color: transparent;
  width: 300px;
  height: 200px;
  perspective: 1000px;
}

.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  /* min-height: 20rem; */
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flipCard:hover .flipCardInner {
  transform: rotateY(180deg);
}

.flipCardFront img {
  height: inherit;
}

.flipCardFront,
.flipCardBack {
  box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  border-radius: 1rem;
}

.flipCardFront {
}

.flipCardBack {
  background: black;
  color: white;
  transform: rotateY(180deg);
}
