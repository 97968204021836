/* @import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

.videoBackground {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%); /* Center the video */
  object-fit: cover; /* Ensures the video covers the container */
  z-index: -1;
  /* filter: blur(2px); */
  mask-image: linear-gradient(to bottom, black, transparent);
  -webkit-mask-image: linear-gradient(to bottom, black, transparent);
}

.hajj_title {
  font-family: "Ubuntu", sans-serif !important;
  font-weight: 700;
}

.custom_font_ubuntu {
  font-family: "Ubuntu", sans-serif !important;
  font-weight: 400;
}
.hajj_description {
  font-family: "Raleway", sans-serif !important;
}

.custom_size {
  font-size: 125px;
  margin: 0;
  margin-top: 10rem;
}

/* From Uiverse.io by joe-watson-sbf */
.flip-card {
  background-color: transparent;
  width: 190px;
  height: 254px;
  perspective: 1000px;
  font-family: sans-serif;
}

.title {
  font-size: 1.5em;
  font-weight: 900;
  text-align: center;
  margin: 0;
}
